import { faLinkedinIn, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FooterMobileComponent from '../FooterMobileComponent/FooterMobileComponent';
import Instagram from '../svgs/Instagram';
import { PAGES } from '../../constants/constants';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

const pages1 = [
  PAGES.HOME,
  PAGES.STORY,
  PAGES.CAREER,
  PAGES.PARTNER_WITH_US,
  PAGES.CONTACT_US,
  PAGES.PATENT_AND_TRADEMARK,
];

const pages2 = [
  PAGES.PRIVACY_POLICY,
  PAGES.TERMS_AND_CONDITIONS,
  PAGES.REFUND_POLICY,
  PAGES.PRESS_OR_MEDIA,
  PAGES.HELP_AND_SUPPORT,
];

const FooterComponent = ({ setShow }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWidthChange = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };

    window.addEventListener('resize', handleWidthChange);

    return () => window.removeEventListener('resize', handleWidthChange);
  });

  return (
    <>
      {windowWidth > 768 ? (
        <Box
          bgcolor={'#000'}
          padding={'20px'}
          sx={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Box
            width={'80%'}
            marginX={'auto'}
          >
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              height={50}
              style={{ borderBottom: '1px solid #999' }}
            >
              <Box>
                <Box
                  display={'flex'}
                  gap={'40px'}
                >
                  {pages1.map(({ title, route }) => (
                    <Typography
                      key={route}
                      className={'cur'}
                      fontSize={'14px'}
                      component={Link}
                      target={route !== '/' && '_blank'}
                      to={route}
                      style={{ textDecoration: 'none' }}
                      fontFamily={'Poppins'}
                    >
                      <span className='text-light'>{title}</span>
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'end'}
                gap={'20px'}
                width={200}
              >
                <a
                  href={'https://www.youtube.com/channel/UCVYcJj6qHAIOa9zBAnLcnbA/'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ color: 'white' }}
                  />
                </a>
                <a
                  href={'https://www.linkedin.com/company/soaron/'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    style={{ color: 'white' }}
                  />
                </a>
                <a
                  href={'https://twitter.com/SoaronOfficial/'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    style={{ color: 'white' }}
                  />
                </a>
                <a
                  href={'https://www.instagram.com/soaronofficial/'}
                  target={'_blank'}
                  rel='noreferrer'
                >
                  <Instagram
                    className='mb-1'
                    color='#fff'
                  />
                </a>
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}
              height={50}
              alignItems={'center'}
            >
              <Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'10px'}
                >
                  {pages2.map(({ title, route }, index) => (
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 10 }}
                      key={route}
                    >
                      <Typography
                        className={'cur'}
                        fontSize={'14px'}
                        component={Link}
                        target={'_blank'}
                        to={route}
                        style={{ textDecoration: 'none' }}
                        fontFamily={'Poppins'}
                      >
                        <span className='text-light'>{title}</span>
                      </Typography>
                      {index < pages2.length - 1 && (
                        <Box
                          width={5}
                          height={5}
                          borderRadius={'50%'}
                          bgcolor={'white'}
                          className='mx-2'
                        ></Box>
                      )}
                    </div>
                  ))}
                </Box>
              </Box>
              <Typography
                fontSize={'14px'}
                fontFamily={'Poppins'}
                color={'white'}
              >
                Copyright <FontAwesomeIcon icon={faCopyright} /> Soaron All Right Reserved
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <FooterMobileComponent />
      )}
    </>
  );
};

export default FooterComponent;
