import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { COLORS } from '../../constants/constants';
import messages from '../../constants/contents';
import styles from './Invest.module.scss';
import './Invest.scss';

const {
  HOME_PAGE: { SECTION_1A, SECTION_1B, SECTION_2, SECTION_4, SECTION_5, SECTION_6 },
} = messages;

const InvestPage = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* 1st section */}
      <div
        style={{
          backgroundColor: '#000',
          background: `url('assets/ellipse.png')`,
          backgroundSize: '700px',
          backgroundPosition: 'center calc(50% - 50px)',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className='main-box align-items-start justify-content-center'
          style={{}}
        >
          <br />
          <br />
          <div className='d-flex flex-column align-items-center justify-content-between'>
            <div className='col-12 col-md-5 d-flex justify-content-center'>
              {/* <video
                style={{
                  position: 'relative',
                  zIndex: 0,
                }}
                autoPlay='autoplay'
                playsInLine='playsinline'
                muted='true'
                className='mx-auto video'
              >
                <source
                  src='assets/HeroComp.mp4'
                  type='video/mp4'
                />

              </video> */}
              <img className='video' src="assets/bg.png" alt="" srcset="" />
            </div>
            <br />
            <br />
            <div
              className='col-12 my-3 px-4 d-flex flex-column justify-content-center'
              style={{ textAlign: 'center', zIndex: 1, maxWidth: 1000 }}
            >
              <div
                className='text1 fw-bold'
                style={{ marginBottom: '1rem', fontFamily: 'aerospace', color: COLORS.LIGHT }}
              >
                {SECTION_1A.TITLE}
              </div>
              <div
                className='text3'
                style={{ marginBottom: '3rem', color: COLORS.MEDIUM_LIGHT }}
              >
                {SECTION_1A.SUBTITLE}
                <p>Safer. Faster.</p>
              </div>
              <div
                className='text3'
                style={{ color: COLORS.MEDIUM_LIGHT }}
              >
                <div className='text3'>{SECTION_1A.DESCRIPTION} </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <div className={`${styles.sectionContainer}`}>
          <div className='main-box'>
            <div className='mt-5'>
              <div
                className='text2 fw-bold text-center text-sm-start'
                style={{ color: COLORS.LIGHT }}
              >
                {SECTION_1B.TITLE}
              </div>
              <div
                className='text3 text-center text-sm-start'
                style={{ color: COLORS.MEDIUM_LIGHT }}
              >
                Benifits that impacts
              </div>
              <br />
              <div className={styles.sec3}>
                <div className={styles.sec3Left}>
                  <div className='row'>
                    {SECTION_1B.LIST.map((item, i) => (
                      <div
                        className='col-6 p-0 d-flex justify-content-center col-md-4 my-4'
                        key={i}
                      >
                        <div
                          className='p-3 mx-2'
                          style={{
                            width: 160, height: 210, border: '1px solid white', borderRadius: 15, background: 'linear-gradient(180deg, rgba(40,40,40,.7) 0%, rgba(0,0,0,.1) 100%)',
                            backdropFilter: 'blur(5px)',
                          }}
                        >
                          <img
                            src={item.ICON}
                            alt=''
                            srcset=''
                            width={18}
                          />
                          <div className='d-flex h-75 flex-column align-items-left justify-content-between'>
                            <div
                              className=' fw-bold my-1'
                              style={{ color: COLORS.LIGHT, fontSize: 20 }}
                            >
                              {item.TITLE}
                            </div>
                            <div
                              className=''
                              style={{ color: COLORS.MEDIUM_LIGHT, fontSize: 20 }}
                            >
                              {item.DESCRIPTION}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.sec3Right}>
                  <img
                    src={'assets/drone2.png'}
                    alt={''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='my-4'></div>
      </div>

      {/* 2nd section */}
      <div
        className={`sec2`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#fff',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: window.innerWidth > 450 ? '100vh' : open ? 'auto' : '500px',
          overflow: 'hidden',
        }}
      >
        <div
          className='main-box'
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <img
            src={'assets/ellipse2.png'}
            alt=''
            srcset=''
            className={open ? 'ellipse ellipse-open' : 'ellipse'}
          />
          {open && (
            <div
              className='journey-title fw-bold text3'
              style={{
                zIndex: 1,
                position: 'relative',
                top: -70,
              }}
            >
              {SECTION_2.TITLE.split('\n').map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          )}

          <div className=''>
            {!open && (
              <div
                className='text1 journey-title fw-bold text-center mt-4'
                style={{ lineHeight: '1.2', color: 'black', maxWidth: 800 }}
              >
                {SECTION_2.TITLE}
              </div>
            )}

            <br />
            <div style={{ fontFamily: 'poppins', maxWidth: 800 }}>
              {open
                ? SECTION_2.DESCRIPTION.split('\n').map((item, i) => (
                  <>
                    <div
                      className='mb-2 text4 text-center'
                      key={i}
                    >
                      {item}
                      <br />
                    </div>
                    <br />
                  </>
                ))
                : SECTION_2.DESCRIPTION.slice(0, 275)
                  .split('\n')
                  .map((item, i) => (
                    <div
                      className='mb-2 text4 text-center'
                      key={i}
                    >
                      {item}...
                    </div>
                  ))}
            </div>
          </div>

          <br />
          {!open && (
            <div
              className={styles.readMore}
              onClick={() => setOpen(true)}
            >
              Read more
            </div>
          )}
        </div>
      </div>

      {/* 4th section */}

      <div className={styles.sectionContainer}>
        <div className='main-box pb-5'>
          <div
            style={{
              position: 'relative',
              height: 100,
            }}
          >
            <img
              src='assets/ellipse.png'
              alt=''
              style={{
                width: 800,
                position: 'absolute',
                left: 'calc(50% - 400px)',
                top: -650,
                margin: 'auto',
                zIndex: 0,
              }}
            />
          </div>

          <div
            className='text3 fw-bold mx-auto'
            style={{ textAlign: 'center', position: 'relative', height: 50 }}
          >
            <div
              className='text3 fw-bold d-flex text-center w-100 justify-content-center align-items-center'
              style={{
                position: 'absolute',
                height: 50,
                zIndex: 1,
                color: COLORS.LIGHT,
                top: 0,
              }}
            >
              OUR PARTNERS
            </div>
            <img
              src='assets/pill.png'
              alt=''
              style={{}}
            />
          </div>
          <br />
          <div className='row mt-5'>
            {SECTION_4.CARDS.map((item, i) => (
              <div
                className='col-12 col-md-6 p-3 d-flex flex-column align-items-center'
                key={i}
              >
                <img
                  src={item.IMAGE}
                  width={'70%'}
                  alt=''
                  className='mb-4'
                />
                {/* <div
                  className='text3 fw-bold'
                  style={{
                    color: COLORS.LIGHT,
                  }}
                >
                  {item.TITLE}
                </div> */}
              </div>
            ))}
          </div>
          <br />
          <div
            className='text3 text-center'
            style={{ maxWidth: 1100, margin: 'auto', color: COLORS.MEDIUM_LIGHT }}
          >
            {SECTION_4.DESCRIPTION}
          </div>
        </div>
      </div>

      <br />

      {/* 5th section */}

      <div
        className={`${styles.sectionContainer}`}
        style={{ backgroundColor: '#000', flexDirection: 'column' }}
      >
        <div className='bg-light'>
          <div className='main-box my-5 d-flex flex-column'>
            <div className='text3 text-center'>Approached for Next Round</div>
            <div className='row my-4'>
              {SECTION_5.ROW_1.map((item, i) => (
                <div
                  key={i}
                  className='col-6 mt-4 px-3 text-center'
                >
                  <img
                    src={item.IMAGE}
                    alt=''
                    width={150}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div
          className='main-box py-5 mt-5 my-md-5 gap-4 d-flex flex-column'
          style={{
            color: COLORS.LIGHT,

            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <div
            className='text1 text-center fw-bold mx-auto'
            style={{ maxWidth: 1200 }}
          >
            {SECTION_5.TITLE}
          </div>
          <div className='d-done d-md-block'></div>
          <div
            className='row  mx-auto'
            style={{
              position: 'relative', width: '85%',


            }}
          >
            <div style={{
              zIndex: 1,
              borderRadius: 30,
              border: '3px solid #848484',
              background: 'linear-gradient(180deg, rgba(40,40,40,.7) 0%, rgba(0,0,0,.1) 100%)',
              backdropFilter: 'blur(5px)',

            }}
              className='cardx d-flex justify-content-center'
            >

              <div
                style={{
                  position: 'absolute',
                  height: 'fit-content',
                  zIndex: 1,
                  borderRadius: 30,

                }}
                className=''
              />
              <div className="row p-2">

                {SECTION_5.ROW_2.map((item, i) => (
                  <div
                    key={i}
                    className='col-12 col-md-4 my-4 px-5 text-center'
                    style={{
                      position: 'relative', zIndex: 1,

                    }}
                  >
                    <img
                      src={item.IMAGE}
                      alt=''
                      width={30}
                    />
                    <div className='h5 my-3 fw-bold'>{item.TITLE}</div>
                    <div
                      className='p'
                      style={{ maxWidth: 400 }}
                    >
                      {item.DESCRIPTION}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 10, textAlign: 'center' }}>
          <img
            src='assets/ellipse.png'
            alt=''
            style={{
              position: 'relative',
              bottom: 200,
              width: 800,
              zIndex: 0,
              left: window.innerWidth > 750 ? 0 : 'calc(50% - 400px)',

            }}
          />
        </div>
      </div>

      {/* 6th section */}

      <div className={`${styles.sectionContainer} bg-light`}>
        <div className='py-5'>
          <div className='text2 fw-bold text-center text-sm-start'>{SECTION_6.TITLE}</div>
          <div
            className='w-md-75 m-auto'
            style={{
              background: 'url(assets/pie.png)',
              backgroundSize: window.innerWidth > 450 ? 300 : 250,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: window.innerWidth > 450 ? 'center' : 'center 40px',
            }}
          >
            <div
              className='d-sm-none'
              style={{ height: 320 }}
            ></div>
            <div className='row container text-center text-sm-start my-md-5 mx-auto'>
              <div className='col-12 col-sm-8 my-2'></div>
              <div className='col-12 col-sm-4 my-2'>
                <div style={{ width: 300 }} className='mx-auto'>
                  <div className='text2 fw-'>{SECTION_6.CARDS[0].PERCENTAGE}</div>
                  <div className='text4 my-2 fw-bold'>{SECTION_6.CARDS[0].TITLE}</div>
                  <div className='text4'>{SECTION_6.CARDS[0].DESCRIPTION}</div>
                </div>
              </div>
              <div className='col-0 col-md-1 my-2'></div>
              <div className='col-12 col-sm-4 my-2'>
                <div style={{ width: 300 }} className='mx-auto'>
                  <div className='text2 fw-'>{SECTION_6.CARDS[1].PERCENTAGE}</div>
                  <div className='text4 my-2 fw-bold'>{SECTION_6.CARDS[1].TITLE}</div>
                  <div className='text4'>{SECTION_6.CARDS[1].DESCRIPTION}</div>
                </div>
              </div>
              <div className='col-0 col-sm-5 col-md-4 my-2'></div>
              <div className='col-12 col-sm-3 my-2'>
                <div style={{ width: 300 }} className='mx-auto'>
                  <div className='text2 fw-'>{SECTION_6.CARDS[2].PERCENTAGE}</div>
                  <div className='text4 my-2 fw-bold'>{SECTION_6.CARDS[2].TITLE}</div>
                  <div className='text4'>{SECTION_6.CARDS[2].DESCRIPTION}</div>
                </div>
              </div>
              <div className='col-12 col-sm-8 my-2'></div>
              <div className='col-12 col-sm-4 my-2'>
                <div style={{ width: 300 }} className='mx-auto'>
                  <div className='text2 fw-'>{SECTION_6.CARDS[3].PERCENTAGE}</div>
                  <div className='text4 my-2 fw-bold'>{SECTION_6.CARDS[3].TITLE}</div>
                  <div className='text4'>{SECTION_6.CARDS[3].DESCRIPTION}</div>
                </div>
              </div>
            </div>
          </div>

          <br />
        </div>
      </div>

      {/* 7th sec */}

      <div className={``}>
        <div className='main-box pt-5'>
          <div
            className='text3 mt-5 fw-bold mx-auto'
            style={{ textAlign: 'center', position: 'relative', height: 50 }}
          >
            <div
              className='text3 fw-bold d-flex text-center w-100 justify-content-center align-items-center'
              style={{
                position: 'absolute',
                height: 50,
                zIndex: 1,
                color: COLORS.LIGHT,
                top: 0,
              }}
            >
              INVEST TODAY
            </div>
            <img
              src='assets/pill.png'
              alt=''
              style={{}}
            />
          </div>
          <div style={{ height: 100 }}></div>
          <div
            className='h-75 d-flex align-items-center justify-content-center'
            style={{ position: 'relative', zIndex: 1 }}
          >
            <div
              className=''
              style={{ position: 'relative' }}
            >
              <img
                src='assets/star1.png'
                alt=''
                style={{
                  position: 'absolute',
                  top: -40,
                  right: 0,
                  zIndex: 0,
                }}
              />
              {SECTION_6.BUTTONS.map((item, i) => (
                <div
                  className='m-4'
                  style={{
                    width: 300,
                    height: 70,
                    backgroundColor: '#707070',
                    borderRadius: 15,
                    zIndex: 1,
                    position: 'relative',
                  }}
                >
                  <a
                    style={{
                      color: 'white',
                      maxWidth: '400px',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    href={item.LINK}
                    className='btn text4'
                  >
                    {item.TITLE}
                    <FontAwesomeIcon
                      className='me-2'
                      icon={item.ICON}
                    />
                  </a>
                </div>
              ))}
              <img
                src='assets/star2.png'
                alt=''
                style={{
                  position: 'absolute',
                  bottom: -20,
                  left: -30,
                  zIndex: 0,
                }}
              />
            </div>
          </div>
          <div
            style={{
              height: 150,
              position: 'relative',
              textAlign: 'center',
              width: '100%',
              maxWidth: 800,
              margin: 'auto',
            }}
          >
            <img
              src='assets/ellipse3.png'
              alt=''
              style={{ position: 'absolute', bottom: -100, width: '100%', zIndex: 0, left: 0 }}
            />
          </div>
        </div>
      </div>

      {/* button */}
    </>
  );
};

export default InvestPage;
