import {
  faLinkedinIn,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Close, DragHandle } from "@mui/icons-material";
import { AppBar, Box, Drawer, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Instagram from "../svgs/Instagram";
import styles from "./NavbarComponent.module.scss";
import { ROUTES, PAGES } from "../../constants/constants";

const pages = [PAGES.HOME, PAGES.STORY, PAGES.CAREER, PAGES.PARTNER_WITH_US, PAGES.CONTACT_US];

const NavbarComponent = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(window.location.pathname);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWidthChange = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };

    window.addEventListener("resize", handleWidthChange);

    return () => window.removeEventListener("resize", handleWidthChange);
  });

  const handleMenuOpenClick = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Box
      className={styles.mainContainer}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      bgcolor={menuOpen && windowWidth > 1000 ? "white" : "#000"}
      style={{ transition: ".5s" }}
      position={"sticky"}
      top={0}
      zIndex={5}
    >
      <>
        <Link
          to={ROUTES.HOME}
          onClick={() => setCurrentPage(ROUTES.HOME)}
          style={{ textDecoration: "none" }}
          className={"d-lg-block d-none"}
        >
          <img
            alt="logo"
            src={"/assets/Black.png"}
            style={{ filter: !menuOpen && "invert(1)" }}
            height={40}
          />
        </Link>
        <Link
          to={ROUTES.HOME}
          onClick={() => setCurrentPage(ROUTES.HOME)}
          style={{ textDecoration: "none" }}
          className={"d-lg-none d-block"}
        >
          <img
            alt="logo"
            src={"/assets/White.png"}
            style={{ filter: menuOpen && windowWidth > 1000 && "invert(1)" }}
            height={20}
          />
        </Link>
      </>

      {menuOpen ? (
        windowWidth > 1000 ? (
          <Box display={"flex"} alignItems={"center"} gap={"2vw"}>
            {
              pages.map(({ title, route }) => (
                <Typography
                  key={route}
                  role={"button"}
                  component={Link}
                  target={route !== '/' && "_blank"}
                  onClick={() => setCurrentPage(route)}
                  style={{ color: "black", fontWeight: currentPage === route && 600 }}
                  to={route}
                  fontFamily={"Poppins"}
                  color={"black"}
                >
                  {title}
                </Typography>
              ))

            }
            {/* {auth.currentUser && <button onClick={() => auth.signOut()} className="btn btn-primary px-4 py-2">Logout</button>} */}
          </Box>
        ) : (
          <AppBar>
            <Drawer
              PaperProps={{
                sx: {
                  width: "280px",
                  maxWidth: "100%",
                },
              }}
              variant="temporary"
              open={menuOpen}
              anchor={"right"}
              onClose={() => setMenuOpen(false)}
            >
              <div className="p-4">
                <div
                  className="d-flex justify-content-end mb-4"
                  onClick={() => setMenuOpen(false)}
                >
                  <Close style={{ color: "black", transform: "scale(1.5)" }} />
                </div>

                <div className="d-flex justify-content-center mb-5">
                  <img
                    alt=""
                    src={"/assets/Black.png"}
                    style={{ width: "150px", marginBottom: "50px" }}
                  />
                </div>

                <div className="container d-flex flex-column gap-5 px-4">
                  {
                    pages.map(({ title, route }) => (
                      <Typography
                        key={route}
                        fontSize={"16px"}
                        component={Link}
                        target={route !== '/' && "_blank"}
                        onClick={() => {
                          setMenuOpen(false);
                        }}
                        style={{ textDecoration: "none" }}
                        to={route}
                        fontFamily={"Poppins"}
                        color={"black"}
                        fontWeight={600}
                      >
                        {title}
                      </Typography>
                    ))
                  }

                </div>
              </div>
            </Drawer>
          </AppBar>
        )
      ) : (
        <></>
      )}

      <Box display={"flex"} alignItems={"center"} gap={"1vw"}>
        {menuOpen && windowWidth > 1000 ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={"20px"}
            width={200}
          >
            <a
              href={"https://www.youtube.com/channel/UCVYcJj6qHAIOa9zBAnLcnbA/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} style={{ color: "black" }} />
            </a>
            <a
              href={"https://www.linkedin.com/company/soaron/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} style={{ color: "black" }} />
            </a>
            <a
              href={"https://twitter.com/SoaronOfficial/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} style={{ color: "black" }} />
            </a>
            <a
              href={"https://www.instagram.com/soaronofficial/"}
              target={"_blank"}
              rel="noreferrer"
              className="py-auto"
            >
              <Instagram className="mb-1" color={"#000"} />
            </a>
          </Box>
        ) : null}

        <IconButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={handleMenuOpenClick}
          className="p-0 ps-3"
        >
          <Typography
            fontFamily={"Poppins"}
            marginRight={"4px"}
            color={"white"}
            className={styles.menu}
          >
            Menu
          </Typography>
          {menuOpen && windowWidth > 1000 ? (
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: "black", fontSize: 27 }}
            />
          ) : (
            <DragHandle className={styles.burger} style={{ color: "white" }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default NavbarComponent;
