import React from "react";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import NavbarComponent from "./components/NavbarComponent/NavbarComponent";
// import { ROUTES } from "./constants/constants";
import InvestPage from "./pages/InvestPage/InvestPage";

// const {
// HOME,
// } = ROUTES

const App = () => {
  // const navigate = useNavigate();

  // React.useEffect(() => {
  //   window.scrollTo(0, 0);
  // onAuthStateChanged(auth, (user) => {
  //   if (user) {
  //     const uid = user.uid;
  //     console.log("uid", uid)
  //     navigate(HOME)
  //   } else {
  //     console.log("user is logged out")
  //     navigate(LOGIN)
  //   }
  // });

  // }, [navigate]);



  return (
    <>
      <div className="d-flex flex-column min-vh-100" >
        <NavbarComponent />
        <InvestPage />
        {/* <Routes>
          <Route path={HOME} element={<InvestPage />} />
          <Route path={LOGIN} element={<AuthPage />} />
        </Routes> */}
        <FooterComponent />
      </div>
    </>
  );
};

export default App;
