export const ROUTES = {
  HOME: 'https://soaron.co/',
  LOGIN: '/login',
  CAREER: 'https://soaron.co/career',
  CAREER_JOBS: 'https://soaron.co/career/jobs',
  CAREER_JOBS_ID: 'https://soaron.co/career/jobs/:id',
  STORY: 'https://soaron.co/story',
  PARTNER_WITH_US: 'https://soaron.co/partner-with-us',
  CONTACT_US: 'https://soaron.co/contact-us',
  PATENT_AND_TRADEMARK: 'https://soaron.co/patent-and-trademark',
  PRIVACY_POLICY: 'https://soaron.co/privacy-policy',
  TERMS_AND_CONDITIONS: 'https://soaron.co/terms-and-conditions',
  REFUND_POLICY: 'https://soaron.co/refund-policy',
  PRESS_OR_MEDIA: 'https://soaron.co/press-or-media',
  HELP_AND_SUPPORT: 'https://soaron.co/contact-us',
  COMING_SOON: '*',
};

export const PAGES = {
  HOME: {
    title: 'Home',
    route: ROUTES.HOME,
  },
  LOGIN: {
    title: 'Login',
    route: ROUTES.LOGIN,
  },
  CAREER: {
    title: 'Career',
    route: ROUTES.CAREER,
  },
  STORY: {
    title: 'Story',
    route: ROUTES.STORY,
  },
  PARTNER_WITH_US: {
    title: 'Partner With Us',
    route: ROUTES.PARTNER_WITH_US,
  },
  CONTACT_US: {
    title: 'Contact Us',
    route: ROUTES.CONTACT_US,
  },
  PATENT_AND_TRADEMARK: {
    title: 'Patent & Trademark',
    route: ROUTES.PATENT_AND_TRADEMARK,
  },
  PRIVACY_POLICY: {
    title: 'Privacy Policy',
    route: ROUTES.PRIVACY_POLICY,
  },
  TERMS_AND_CONDITIONS: {
    title: 'Terms & Conditions',
    route: ROUTES.TERMS_AND_CONDITIONS,
  },
  REFUND_POLICY: {
    title: 'Refund Policy',
    route: ROUTES.REFUND_POLICY,
  },
  PRESS_OR_MEDIA: {
    title: 'Press or Media',
    route: ROUTES.PRESS_OR_MEDIA,
  },
  HELP_AND_SUPPORT: {
    title: 'Help & Support',
    route: ROUTES.HELP_AND_SUPPORT,
  },
};

export const CONTENT_TYPES = {
  PARA: 'para',
  IMAGE: 'image',
  VIDEO: 'video',
  LIST: 'list',
  TABLE: 'table',
  HEADING: 'heading',
  SUB_HEADING: 'sub-heading',
  TEXT: 'text',
};

export const COLORS = {
  LIGHT: '#FFFFFF',
  MEDIUM_LIGHT: '#EEEEEE',
  DARK: '#000000',
  PRIMARY: '#1D1D1D',
  SECONDARY: '#F8F8F8',
  TERTIARY: '#F8F8F8',
  BG: '#0f151a',
};
